import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesJointOTV: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Joint Training - Overloaded TV</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_otv.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Joint Training - Overloaded TV</h1>
          <h2>
            A dedicated guide for the Joint Training - Overloaded TV in Ash
            Echoes. Beat the 300% difficulty with ease with our guide!
          </h2>
          <p>
            Last updated: <strong>27/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <StaticImage
              src="../../../images/ash/event/otv_1.webp"
              alt="Guides"
            />
            <SectionHeader title="Introduction" />
            <p>
              In this guide we go over the Overloaded TV, the very first Joint
              training Boss. While the guide is primarily written for 200%
              difficulty in mind, any point covered here also applies to higher
              difficulties all the way up to 300%. The main differences will be
              stricter requirements on the builds (Ascension, Skill levels,
              levels of the Traces, etc…) and more precise execution of the
              gameplay.
            </p>
            <p>
              The Boss isn’t hard as it’s the first one of its kind, and only a
              few of its gimmicks and mechanics pose a threat to you, so we’ll
              focus on those and how to build a team to play around them.
            </p>
            <SectionHeader title="The Quick and Tidy" />
            <p>
              Here’s all you need to know for the strategy if your account is
              advanced enough, going over only the most dangerous mechanics of
              the Boss:
            </p>
            <ul>
              <li>
                The TV has <strong>up to 3 Health Bars</strong> (representing 1
                Phase each), and <strong>punishes Burst damage</strong>. For
                those reasons, <strong>aim for good DPS</strong> over longer
                periods of time rather than quick Burst;
              </li>
              <li>
                Because{' '}
                <strong>
                  the Boss (and only the Boss) reflects 8% of their damage back
                  at your Echomancers, high VIT and DEF are highly advised
                </strong>{' '}
                in order to survive your own output. It is also recommended to
                go for Strikers;
              </li>
              <li>
                The ‘Cloud Radiation’ modifier counts as a DoT field effect that
                can be{' '}
                <strong>
                  countered by the ‘Detox Domain’ from the Hailin Streets Nexus
                  path
                </strong>
                , so if the AoE damage is too bothersome go ahead and pick that
                skill up;
              </li>
              <li>
                Thermal Explosion<strong> is a game over mechanic</strong> that
                takes a while to be cast by the TV. 4 Mini Robots are summoned
                on the map and start channeling some kind of red/orange energy
                into the Boss. If you fail to{' '}
                <strong>
                  kill these mini bots before the AoE outline reaches the end of
                  the battlefield
                </strong>
                , you lose immediately;
              </li>
              <li>
                Once you finish the 2nd Health Bar, Phase 3 starts with no
                break, and it’s the one that will make sure that not matter how
                defensive your build is, you still need Offense or it’s GG:
              </li>
              <ul>
                <li>
                  <strong>You have 4 minutes</strong> to put the TV out of
                  commission once and for all, otherwise it blows up and it’s
                  time to go back all the way to the start;
                </li>
                <li>
                  Many mobs are summoned throughout Phase 3.{' '}
                  <strong>AoE is key for this gimmick</strong>;
                </li>
                <li>
                  <strong>The Boss still reflects damage</strong>, and by now
                  your Inspire and all your Burst sources are available and
                  stacked. If you have access to Burst dealers, now’s their time
                  to shine. Otherwise, no need to hurry, the Boss will fall to
                  your relentless assault in due time.
                </li>
              </ul>
            </ul>
            <SectionHeader title="Team Building and Examples" />
            <p>
              Here’s a list for each role of Echomancers who, in our opinion,
              make the encounter easier and more forgiving. Note that those
              recommendations <strong>are not</strong> designed for
              speedrunning, which can result in a safe but lengthy fight.
            </p>
            <ul>
              <li>
                <strong>Tanks</strong>: To no one’s surprise,{' '}
                <AshCharacter
                  slug="lorelle"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                is the premiere tank for the encounter thanks to her teamwide
                damage mitigation. This is arguably the easiest role to fill as
                even{' '}
                <strong>
                  <AshCharacter
                    slug="rodney"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  and other 4 star
                </strong>{' '}
                Tanks can be played in that spot, so long as their tanking does
                not heavily rely on Active Skills;
              </li>
              <li>
                <strong>Healers</strong>: Plenty of choice here too, as long as
                you have one to two Healers to keep your team topped up you’re
                good.{' '}
                <AshCharacter
                  slug="longqing"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                really shines but only during phase 3 so keep that in mind;
              </li>
              <li>
                <strong>DPS</strong>: Prioritize Strikers to Rangers and
                Skirmishers as they’re generally sturdier. Steady wins the race,
                especially in the first 2 phases. You can also do the opposite
                with the Necromancer Team posted below, where you’ll play one
                hyper carry and a bunch of buffers.
              </li>
            </ul>
            <p>
              <strong>Note</strong>: Traces that greatly synergize with Strikers
              and frontliners are great to stack if you’re going for a slower
              setup, in which case
              <AshTrace name="Starlit Memories" mode="data" onProfile /> is an
              awesome budget option, and others like{' '}
              <strong>
                <AshTrace name="Company" mode="data" onProfile />,
                <AshTrace
                  name="Behind The Curtains"
                  mode="data"
                  onProfile
                />{' '}
                and
                <AshTrace name="Managing Marks" mode="data" onProfile />
              </strong>{' '}
              also work well to add both bulk and damage.
            </p>
            <h5>The ‘Safety First’ Team</h5>
            <StaticImage
              src="../../../images/ash/event/otv_2.webp"
              alt="Guides"
            />
            <p>
              So long as your Build is decent, it’s practically impossible to
              lose due to a lack of damage. On the contrary, you might have too
              much damage and obliterate your own carries instead, which is why
              we aim for a double Support + Tank + DPS, with the DPS having
              reasonable output instead of being a hyper carry.
            </p>
            <p>
              In the same way, Corrosive Blast does not damage your Team as the
              Burst comes from the Terminal, giving you some extra juice early
              on in each phase to speed up the fight.
            </p>
            <p>
              Try to keep a Striker with AoEs at hand, to make clearing the
              trash mobs easier at the start of Phase 2 and during Phase 3.
            </p>
            <h5>The Necromancer Team</h5>
            <StaticImage
              src="../../../images/ash/event/otv_3.webp"
              alt="Guides"
            />
            <p>Here’s our take on the Necromancer Team:</p>
            <p>
              This one is all about buffing{' '}
              <AshCharacter
                slug="cen-ying"
                mode="inline"
                showLabel
                showTags
                showIcon
                enablePopover
              />{' '}
              to the moon and blasting through all 3 phases of the Boss with a
              single Ultimate.
            </p>
            <p>
              By exploiting the revive mechanic of{' '}
              <AshCharacter
                slug="su-xiao"
                mode="inline"
                showLabel
                showTags
                showIcon
                enablePopover
              />{' '}
              and combining it with the Cheat Death granted by{' '}
              <AshCharacter
                slug="gauya"
                mode="inline"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              , a well positioned Ult from Cen Ying can take care of the entire
              fight by itself, once it’s received all the juicy buffs.
            </p>
            <p>
              If the Radioactive Cloud effect deals too much damage to you and
              disrupts your gameplan, pick up the ‘Detox Domain’ skill from the
              Hailin Streets path.
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesJointOTV;

export const Head: React.FC = () => (
  <Seo
    title="Joint Training - Overloaded TV | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for the Joint Training - Overloaded TV in Ash Echoes. Beat the 300% difficulty with ease with our guide!"
    game="ash"
  />
);
